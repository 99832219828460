import './About.scss';

const About = () => {


    return (
        <div id='about' className="about mt-3 mb-3">
            <div className="container">
                <div className="row">
                    <h2 className='heading text-uppercase'>About us</h2>
                    <h5>We are on a mission to empower technology professionals to do their best work with the power of AI</h5>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-6 col-md-12 p-0">
                        <div className="left-image">
                            <div className="link-container">
                                <a className='start-btn' href='#pricing'>Get started</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 p-0">
                        <div className="top-text-box">
                            <p className='text-justify p-3'>
                                AI System empowers technology professionals to use the latest artificial intelligence (AI) models to generate ideas, improve productivity, and rapidly accomplish tasks in minutes instead of hours/days.
                            </p>
                        </div>
                        <div className="bottom-text-box">
                            <p className='text-justify p-3'>
                                We strongly believe AI is not here to replace our jobs but to supercharge our abilities. AI System is the first AI asisstant dedicated to technology professionals. We built Pace AI to empower, boost confidence and increase productivity.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;