import React from 'react'
import './Companion.scss'
import 'bootstrap/dist/css/bootstrap.css';

const Companion = () => {

    return (
        <div className="companion">
            <div className="container-fluid">
                <div className="container">
                        <div className="row">
                            <h3>Generate ideas in seconds instead of days with the power of AI.</h3>
                        </div>
                        <div className="row">
                            <h2>The <span>AI Companion</span> for</h2>
                        </div>
                        <div className="row">
                            <ul>
                                <li>
                                    <span>
                                        Technology Proffesional
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        Project Managers
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        Product Owners
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        Business Analyst
                                    </span>
                                </li>
                            </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Companion;