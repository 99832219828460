import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Navigation from "./Components/Navigation/Navigation";
import Companion from "./Components/Companion/Companion";
import About from "./Components/About/About";
import System from "./Components/System/System";
import Ideas from "./Components/Ideas/Ideas";
import Pricing from "./Components/Pricing/Pricing";
import Questions from "./Components/Questions/Questions";
import Footer from "./Components/Footer/Footer";
import "bootstrap-icons/font/bootstrap-icons.css";

function App() {

  return (
    <div className="App w-100">
      <Navigation />
      <Companion />
      <About />
      <System />
      <Ideas />
      <Pricing />
      <Questions />
      <Footer />
    </div>
  );
}

export default App;
