import './Navigation.scss'
import 'bootstrap/dist/css/bootstrap.css';
import "../../../node_modules/bootstrap/dist/js/bootstrap.min.js";
import { Navbar } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';

const Navigation = () => {
  return (
    <header>
      <Navbar expand="lg" data-bs-theme="dark" className="bg-body-tertiary p-2 m-0 nav-bar">
        <Container className='bg-black'>
          <img src='./images/logo.webp' />
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="basic-navbar-nav">

          <Nav className="ms-auto mt-3">
            <Nav.Link className='text-white fw-bold nav-link ps-4 pe-4' href="#about">About</Nav.Link>
            <Nav.Link className='text-white fw-bold nav-link ps-4 pe-4' href="#tools">Tools</Nav.Link>
            <Nav.Link className='text-white fw-bold nav-link ps-4 pe-4' href="#pricing">Pricing</Nav.Link>
            <Nav.Link className='text-white fw-bold nav-link ps-4 pe-4' href="#faq">FAQ</Nav.Link>
          </Nav>

          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Navigation;