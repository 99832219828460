import './Ideas.scss'

const Ideas = () => {
    return (
        <div className="container">
            <div className="row">
                <h2 className="heading">How it works</h2>
            </div>
            <div className="row">
                <div className="col-lg-6 col-md-12 mt-5">
                    <div className="idea-container">

                        <img className='robot-img' src='./images/robot.webp' />
                        <div className="idea-box mx-auto">
                            <div className="icon mt-3">
                                <a href='#'>
                                    <i className="bi bi-arrow-left-short"></i>
                                </a>
                            </div>
                            <div className="idea-box-content align-self-end mx-auto mb-4">
                                <h3>Product/Project Description</h3>
                                <p>An online marketplace to sell books</p>
                                <button className='generate-btn'>Generate</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 mt-4">
                    <div className="row mt-5">
                        <div className="instruction-box mx-auto mb-3 mt-3">
                            <div className="row">
                                <div className="col-2 align-self-center">
                                    <span className='fw-bold'>1</span>
                                </div>
                                <div className="col-10 align-self-end pt-4">
                                    <h3 className='fw-bold text-start ps-2'>Choose a tool you want</h3>
                                    <p className='text-start ps-3'>There are over 25+ options</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="instruction-box mx-auto mb-3 mt-3">
                            <div className="row">
                                <div className="col-2 align-self-center">
                                    <span className='fw-bold'>2</span>
                                </div>
                                <div className="col-10 align-self-end pt-4">
                                    <h3 className='fw-bold text-start ps-2'>Enter some descriptions</h3>
                                    <p className='text-start ps-3'>Describe your project or product</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="instruction-box mx-auto mb-3 mt-3">
                            <div className="row">
                                <div className="col-2 align-self-center">
                                    <span className='fw-bold'>3</span>
                                </div>
                                <div className="col-10 align-self-end pt-4">
                                    <h3 className='fw-bold text-start ps-2'>Select Generate</h3>
                                    <p className='text-start ps-3'>Sit back while the AI creates for you</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Ideas;