import './Questions.scss'
import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import "../../../node_modules/bootstrap/dist/js/bootstrap.min.js";

const Questions = () => {
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);



    return (
        <div id='faq' className="container mb-5">
            <h2 className='heading text-uppercase mt-5'>Frequently asked questions</h2>
            <div className="row m-1 mt-5">
                <Button
                    onClick={() => setOpen1(!open1)}
                    aria-controls="collapse-text"
                    aria-expanded={open1}
                >
                    <div className="row p-2">
                        <div className="col-11">
                            <h4 className='fw-bold text-start'>What can I create with AI Service ?</h4>
                        </div>
                        <div className="col-1">
                            <i className={open1 ? "bi bi-arrow-down-circle collapse-icon" : "bi bi-arrow-up-circle collapse-icon"}></i>
                        </div>
                    </div>
                </Button>
                <Collapse in={open1}>
                    <div id="collapse-text">
                        <p className='p-2'>
                        PaceAI is primarily built to be able to perform any task that you do as a technology professional. We call them professional 'use case' and we build tools to cover these use cases.
                        </p>
                        <p className='p-2'>
                        Currently, with PaceAI, you can generate user stories, create acceptance criteria, generate requirements, create problem statement, roadmap, product vision, and much more. 
                        </p>
                        <p className='p-2'>
                        We are constantly launching new tools and professional categories to cover all use cases.
                        </p>
                    </div>
                </Collapse>
            </div>
            <div className="row m-1 mt-5">
                <Button
                    onClick={() => setOpen2(!open2)}
                    aria-controls="collapse-text"
                    aria-expanded={open2}
                >
                    <div className="row p-2">
                        <div className="col-11">
                            <h4 className='fw-bold text-start'>Is AI Service really free?</h4>
                        </div>
                        <div className="col-1">
                            <i className={open2 ? "bi bi-arrow-down-circle collapse-icon" : "bi bi-arrow-up-circle collapse-icon"}></i>
                        </div>
                    </div>
                </Button>
                <Collapse in={open2}>
                    <div id="collapse-text">
                        <p className='p-2'>
                        PaceAI is primarily built to be able to perform any task that you do as a technology professional. We call them professional 'use case' and we build tools to cover these use cases.
                        </p>
                        <p className='p-2'>
                        Currently, with PaceAI, you can generate user stories, create acceptance criteria, generate requirements, create problem statement, roadmap, product vision, and much more. 
                        </p>
                        <p className='p-2'>
                        We are constantly launching new tools and professional categories to cover all use cases.
                        </p>
                    </div>
                </Collapse>
            </div>
            <div className="row m-1 mt-5">
                <Button
                    onClick={() => setOpen3(!open3)}
                    aria-controls="collapse-text"
                    aria-expanded={open3}
                >
                    <div className="row p-2">
                        <div className="col-11">
                            <h4 className='fw-bold text-start'>Can I use the generated copy anywhere?</h4>
                        </div>
                        <div className="col-1">
                            <i className={open3 ? "bi bi-arrow-down-circle collapse-icon" : "bi bi-arrow-up-circle collapse-icon"}></i>
                        </div>
                    </div>
                </Button>
                <Collapse in={open3}>
                    <div id="collapse-text">
                        <p className='p-2'>
                        PaceAI is primarily built to be able to perform any task that you do as a technology professional. We call them professional 'use case' and we build tools to cover these use cases.
                        </p>
                        <p className='p-2'>
                        Currently, with PaceAI, you can generate user stories, create acceptance criteria, generate requirements, create problem statement, roadmap, product vision, and much more. 
                        </p>
                        <p className='p-2'>
                        We are constantly launching new tools and professional categories to cover all use cases.
                        </p>
                    </div>
                </Collapse>
            </div>
            <div className="row m-1 mt-5">
                <Button
                    onClick={() => setOpen4(!open4)}
                    aria-controls="collapse-text"
                    aria-expanded={open4}
                >
                    <div className="row p-2">
                        <div className="col-11">
                            <h4 className='fw-bold text-start'>How does PaceAI compare to ChatGPT?</h4>
                        </div>
                        <div className="col-1">
                            <i className={open4 ? "bi bi-arrow-down-circle collapse-icon" : "bi bi-arrow-up-circle collapse-icon"}></i>
                        </div>
                    </div>
                </Button>
                <Collapse in={open4}>
                    <div id="collapse-text">
                        <p className='p-2'>
                        PaceAI is primarily built to be able to perform any task that you do as a technology professional. We call them professional 'use case' and we build tools to cover these use cases.
                        </p>
                        <p className='p-2'>
                        Currently, with PaceAI, you can generate user stories, create acceptance criteria, generate requirements, create problem statement, roadmap, product vision, and much more. 
                        </p>
                        <p className='p-2'>
                        We are constantly launching new tools and professional categories to cover all use cases.
                        </p>
                    </div>
                </Collapse>
            </div>

            <h2 className='heading text-uppercase mt-5'>Ready to create?</h2>
            <p className='sub-heading mb-5'>10x your productivity, boost your confidence and find passion in your work again</p>
            <a href="#pricing" className='start-btn' >Get started</a>

            
        </div>
    );
}

export default Questions;