import "./Pricing.scss"
import { forwardRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useEffect } from "react";

const Pricing = () => {


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const subscription = (subscriptionId) => {
        handleShow();

        setTimeout(() => {
            if (typeof window.paypal !== undefined) {
                window?.paypal?.Buttons({
                    style: {
                        shape: 'pill',
                        color: 'gold',
                        layout: 'vertical',
                        label: 'subscribe'
                    },
                    createSubscription: function(data, actions) {
                        return actions.subscription.create({
                            /* Creates the subscription */
                            plan_id: subscriptionId,
                        });
                    },
                    onApprove: function(data, actions) {

                    }
                }).render('#paypal-button');
            }
        }, 100);
    };

    useEffect(() => {
        const script = document.createElement("script");

        script.src = "https://www.paypal.com/sdk/js?client-id=AUL8iaCXEa7alYqXJ2pW-4McWXseie5KqtD1lJNJ_Tqp9pvTa0ZfNEhSCY6WrmXhwVbsvyPxN94FZLcw&vault=true&intent=subscription";
        script.setAttribute('data-sdk-integration-source', "button-factory");
        script.async = true;
        document.body.appendChild(script);
    }, []);


    return (
        <div id="pricing" className="pricing pt-5" >




            <div className="container">
                <div className="row">
                    <div className="heading-box">
                        <h2 className="m-1">Pricing</h2>
                        <p>Start creating your best work 10x faster with AI</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <div className="price-box mt-4 ms-1 unpopular">
                            <h3 className="text-uppercase fw-bold">Basic</h3>
                            <div className="rate-box">
                                <span className="dollar-span pe-1">
                                    $
                                </span>
                                <span className="number-span p-2 fw-bolder">
                                    29
                                </span>
                                <span className="month-span fw-lighter">
                                    /Mo
                                </span>
                            </div>
                            <p className="p-2 mb-1">
                                Try our free tools and features.<br /> No credit required
                            </p>
                            <a href="#" className="start-btn fw-bolder"  onClick={() => subscription('P-98H414637T225984RMS4PYCQ')}>Get started NOW</a>

                            <ul className="features-list pt-4 ps-4 pe-4">
                                <li>
                                    <div className="row pt-2 pb-2">
                                        <div className="col-9 offset-1 text-start">
                                            <span>
                                                25+ proffesional PaceAI tools
                                            </span>
                                        </div>
                                        <div className="col-1">
                                            <i className="bi bi-check-circle-fill blue-fill"></i>
                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <div className="row pt-2 pb-2">
                                        <div className="col-9 offset-1 text-start">
                                            <span>
                                                3000 PaceAI response
                                            </span>
                                        </div>
                                        <div className="col-1">
                                            <i className="bi bi-check-circle-fill blue-fill"></i>
                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <div className="row pt-2 pb-2">
                                        <div className="col-9 offset-1 text-start">
                                            <span>
                                                Pace editor (Google docs style editor)
                                            </span>
                                        </div>
                                        <div className="col-1">
                                            <i className="bi bi-check-circle-fill blue-fill"></i>
                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <div className="row pt-2 pb-2">
                                        <div className="col-9 offset-1 text-start">
                                            <span>
                                                1-click copy export
                                            </span>
                                        </div>
                                        <div className="col-1">
                                            <i className="bi bi-check-circle-fill blue-fill"></i>
                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <div className="row pt-2 pb-2">
                                        <div className="col-9 offset-1 text-start">
                                            <span>
                                                Secure data
                                            </span>
                                        </div>
                                        <div className="col-1">
                                            <i className="bi bi-check-circle-fill blue-fill"></i>
                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <div className="row pt-2 pb-2">
                                        <div className="col-9 offset-1 text-start">
                                            <span>
                                                Access to new features
                                            </span>
                                        </div>
                                        <div className="col-1">
                                            <i className="bi bi-check-circle-fill gray-fill"></i>
                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <div className="row pt-2 pb-2">
                                        <div className="col-9 offset-1 text-start">
                                            <span>
                                                Priority email support
                                            </span>
                                        </div>
                                        <div className="col-1">
                                            <i className="bi bi-check-circle-fill gray-fill"></i>
                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <div className="row pt-2 pb-2">
                                        <div className="col-9 offset-1 text-start">
                                            <span>
                                                Unlimited response
                                            </span>
                                        </div>
                                        <div className="col-1">
                                            <i className="bi bi-check-circle-fill gray-fill"></i>
                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <div className="row pt-2 pb-2">
                                        <div className="col-9 offset-1 text-start">
                                            <span>
                                                Auto-update to the newest AI models
                                            </span>
                                        </div>
                                        <div className="col-1">
                                            <i className="bi bi-check-circle-fill gray-fill"></i>
                                        </div>
                                    </div>

                                </li>


                            </ul>
                        </div>


                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="price-box mt-4 popular">
                            <h3 className="most-popular fw-bolder mx-auto p-2">Most Popular</h3>
                            <h3 className="text-uppercase fw-bold pt-1">Pro monthly</h3>
                            <div className="rate-box">
                                <span className="dollar-span pe-1">
                                    $
                                </span>
                                <span className="number-span p-1 fw-bolder">
                                    79
                                </span>
                                <span className="month-span fw-lighter">
                                    /Mo
                                </span>
                            </div>
                            <p className="mb-2 fw-bold">
                                Unlocks all tools<br />
                                <span className="cancel">
                                    Cancel any time
                                </span>
                            </p>
                            <a href="#" className="start-btn fw-bolder" onClick={() => subscription('P-5DV59603HL375383NMS4PYVY')}>Get started NOW</a>
                            <ul className="features-list pt-3 ps-4 pe-4 pb-1">
                                <li>
                                    <div className="row pt-2 pb-2">
                                        <div className="col-9 offset-1 text-start">
                                            <span>
                                                25+ proffesional PaceAI tools
                                            </span>
                                        </div>
                                        <div className="col-1">
                                            <i className="bi bi-check-circle-fill blue-fill"></i>
                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <div className="row pt-2 pb-2">
                                        <div className="col-9 offset-1 text-start">
                                            <span>
                                                3000 PaceAI response
                                            </span>
                                        </div>
                                        <div className="col-1">
                                            <i className="bi bi-check-circle-fill blue-fill"></i>
                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <div className="row pt-2 pb-2">
                                        <div className="col-9 offset-1 text-start">
                                            <span>
                                                Pace editor (Google docs style editor)
                                            </span>
                                        </div>
                                        <div className="col-1">
                                            <i className="bi bi-check-circle-fill blue-fill"></i>
                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <div className="row pt-2 pb-2">
                                        <div className="col-9 offset-1 text-start">
                                            <span>
                                                1-click copy export
                                            </span>
                                        </div>
                                        <div className="col-1">
                                            <i className="bi bi-check-circle-fill blue-fill"></i>
                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <div className="row pt-2 pb-2">
                                        <div className="col-9 offset-1 text-start">
                                            <span>
                                                Secure data
                                            </span>
                                        </div>
                                        <div className="col-1">
                                            <i className="bi bi-check-circle-fill blue-fill"></i>
                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <div className="row pt-2 pb-2">
                                        <div className="col-9 offset-1 text-start">
                                            <span>
                                                Access to new features
                                            </span>
                                        </div>
                                        <div className="col-1">
                                            <i className="bi bi-check-circle-fill blue-fill"></i>
                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <div className="row pt-2 pb-2">
                                        <div className="col-9 offset-1 text-start">
                                            <span>
                                                Priority email support
                                            </span>
                                        </div>
                                        <div className="col-1">
                                            <i className="bi bi-check-circle-fill blue-fill"></i>
                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <div className="row pt-2 pb-2">
                                        <div className="col-9 offset-1 text-start">
                                            <span>
                                                Unlimited response
                                            </span>
                                        </div>
                                        <div className="col-1">
                                            <i className="bi bi-check-circle-fill blue-fill"></i>
                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <div className="row pt-2 pb-2">
                                        <div className="col-9 offset-1 text-start">
                                            <span>
                                                Auto-update to the newest AI models
                                            </span>
                                        </div>
                                        <div className="col-1">
                                            <i className="bi bi-check-circle-fill gray-fill"></i>
                                        </div>
                                    </div>

                                </li>


                            </ul>
                        </div>


                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="price-box mt-4 ms-1 unpopular">
                            <h3 className="text-uppercase fw-bold">Pro Annual</h3>
                            <div className="rate-box">
                                <span className="dollar-span pe-1">
                                    $
                                </span>
                                <span className="number-span p-2 fw-bolder">
                                    319
                                </span>
                                <span className="month-span fw-lighter">
                                    /Mo
                                </span>
                            </div>
                            <h6 className="free-month-text fw-bolder ms-5 me-5 mt-2 pt-2 pb-2">
                                Get 1 month for free
                            </h6>
                            <a href="#" className="start-btn fw-bolder" onClick={() => subscription('P-70B8971431225441VMS4PY2A')}>Get started NOW</a>
                            <ul className="features-list pt-4 ps-4 pe-4">
                                <li>
                                    <div className="row pt-2 pb-2">
                                        <div className="col-9 offset-1 text-start">
                                            <span>
                                                25+ proffesional PaceAI tools
                                            </span>
                                        </div>
                                        <div className="col-1">
                                            <i className="bi bi-check-circle-fill blue-fill"></i>
                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <div className="row pt-2 pb-2">
                                        <div className="col-9 offset-1 text-start">
                                            <span>
                                                3000 PaceAI response
                                            </span>
                                        </div>
                                        <div className="col-1">
                                            <i className="bi bi-check-circle-fill blue-fill"></i>
                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <div className="row pt-2 pb-2">
                                        <div className="col-9 offset-1 text-start">
                                            <span>
                                                Pace editor (Google docs style editor)
                                            </span>
                                        </div>
                                        <div className="col-1">
                                            <i className="bi bi-check-circle-fill blue-fill"></i>
                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <div className="row pt-2 pb-2">
                                        <div className="col-9 offset-1 text-start">
                                            <span>
                                                1-click copy export
                                            </span>
                                        </div>
                                        <div className="col-1">
                                            <i className="bi bi-check-circle-fill blue-fill"></i>
                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <div className="row pt-2 pb-2">
                                        <div className="col-9 offset-1 text-start">
                                            <span>
                                                Secure data
                                            </span>
                                        </div>
                                        <div className="col-1">
                                            <i className="bi bi-check-circle-fill blue-fill"></i>
                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <div className="row pt-2 pb-2">
                                        <div className="col-9 offset-1 text-start">
                                            <span>
                                                Access to new features
                                            </span>
                                        </div>
                                        <div className="col-1">
                                            <i className="bi bi-check-circle-fill blue-fill"></i>
                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <div className="row pt-2 pb-2">
                                        <div className="col-9 offset-1 text-start">
                                            <span>
                                                Priority email support
                                            </span>
                                        </div>
                                        <div className="col-1">
                                            <i className="bi bi-check-circle-fill blue-fill"></i>
                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <div className="row pt-2 pb-2">
                                        <div className="col-9 offset-1 text-start">
                                            <span>
                                                Unlimited response
                                            </span>
                                        </div>
                                        <div className="col-1">
                                            <i className="bi bi-check-circle-fill blue-fill"></i>
                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <div className="row pt-2 pb-2">
                                        <div className="col-9 offset-1 text-start">
                                            <span>
                                                Auto-update to the newest AI models
                                            </span>
                                        </div>
                                        <div className="col-1">
                                            <i className="bi bi-check-circle-fill blue-fill"></i>
                                        </div>
                                    </div>

                                </li>


                            </ul>
                        </div>


                    </div>
                </div>
            </div>


            <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className="mb-0">
                    <div id="paypal-button"></div>
                </Modal.Body>
            </Modal>



        </div>
    );
}

export default forwardRef(Pricing);