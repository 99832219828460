import './System.scss';
import 'bootstrap/dist/css/bootstrap.css';

const System = () => {


    return (
        <div id='tools' className="system mt-5">
            <div className="container">
                <div className="row">
                    <h2 className='heading text-uppercase mb-5'>WHAT YOU CAN DO WITH AI SYSTEM</h2>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="info-box text-start p-4">
                            <img src='./images/suitcase.webp' />
                            <h3>Product Requirements</h3>
                            <p>Generate requirements for your project or product</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="info-box text-start p-4">
                            <img src='./images/stories.webp' />
                            <h3>User stories</h3>
                            <p>Generate user stories for your product or project</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="info-box text-start p-4">
                            <img src='./images/roadmap.webp' />
                            <h3>Roadmap</h3>
                            <p>Create roadmaps for your product or project</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="info-box text-start p-4">
                            <img src='./images/vision.webp' />
                            <h3>Product Vision Statement</h3>
                            <p>Create a product vision statement that inspires.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="info-box text-start p-4">
                            <img src='./images/meeting.webp' />
                            <h3>Kick-off Meeting Agenda</h3>
                            <p>Instantly create a meeting agenda for your project.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="info-box text-start p-4">
                            <img src='./images/report.webp' />
                            <h3>Project Report Status</h3>
                            <p>Summarise project updates into digestible bullet points.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                        <div className="text-box">
                            <h3 className='fw-bolder'>Generate ideas faster</h3>
                            <p className='fs-6 p-3'>
                                Leverage AI to create rapid ideas for your project or product in minutes instead of days.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                        <div className="text-box">
                            <h3 className='fw-bolder'>Contribute confidently</h3>
                            <p className='fs-6 p-3'>
                                Supercharge the value you add to conversations with your stakeholders.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                        <div className="text-box">
                            <h3 className='fw-bolder'>25+ AI tools in your toolkit</h3>
                            <p className='fs-6 p-3'>
                                We are constantly releasing new tools to help you create your best work.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="link-container mt-5 mb-5">
                        <a className='start-btn' href='#pricing'>Get started</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default System;
