import './Footer.scss'

const Footer = () => {
    return (
        <footer>
            <div className="row p-4">
                <img className='mx-auto' src="./images/logo.webp" alt='logo' />
            </div>
        </footer>
    );
}

export default Footer;